import {React} from 'react';

import "./footer.css"

export default function Footer() {
    return (
        <footer className="footer">
            <div>
                <p>&copy; LTJ Web Designs 2023</p>
            </div>
        </footer>
    )
}